import React from 'react'
import { documents } from "../../constants/document_paths"
import './render_utils.scss'

export const renderTermsOfUse = (props, isLogin) => {
  const { t, i18n: { language } } = props
  const createLink = (label, link) => {
    if (!link) {
      return null
    }
    return (
      <a
        key={label}
        styleName="bl-link"
        href={`https://vacation-stay.jp/info/${link}${(language === 'ja') ? '' : '_en'}.pdf`}
        target="_blank"
      >
        {t(`sign_in.${label}`)}
      </a>
    )
  }
  const getTermsTxt = term => {
    return Array.isArray(term) && term.map(label => {
      let link = label
      switch (label) {
      case 'agreement':
        link = documents.agreement
        break
      case 'host_service_agreement':
        link = documents.host_service_agreement
        break
      case 'privacy_policy':
        link = documents.privacy_policy
        break
      }
      return label.match(new RegExp("agreement|host_service_agreement|privacy_policy")) ? createLink(label, link) : label
    })
  }
  return (
    <div styleName={props.modal.isSocialLoginModalShow ? "social-login-form__note" : "login-form__note"} tx-content="translate_urls">
      <p>{getTermsTxt(t(isLogin ? 'sign_in.terms_of_use_first_half' : 'sign_in.register.terms', { returnObjects: true }))}</p>
      {!isLogin &&
        <p>{t('sign_in.terms_of_use_second_half')}</p>
      }
    </div>
  )
}

export default renderTermsOfUse
