import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router'
import { reduxForm, Field, propTypes } from 'redux-form'
import * as loginActions from '../../actions/login'
import * as modalActions from '../../actions/modal'
import {documents} from "../../constants/document_paths"
import './modal_register_component.scss'
import i18n from '../../../i18n'

class ModalRegister extends Component {
  static propTypes = {
    ...propTypes,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    modalActions: PropTypes.object.isRequired,
    onCloseModal: PropTypes.func.isRequired
  }

  componentWillUnmount() {
    this.props.loginActions.clearErrors()
  }

  onSubmit = async formProps => {
    try {
      const { history: { location: { pathname, search } }, t } = this.props
      const returnUrl = this.props.login.returnUrl || `${pathname}${search}`
      await this.props.loginActions.signup(formProps, returnUrl)
      this.props.modalActions.showCommonModal({
        title: t('sign_in.errors.unauthorized_title'),
        subTitle: t('sign_in.errors.unauthorized_subtitle'),
        body: t('sign_in.errors.unauthorized_body')
      })
    } catch (e) {}
  }

  renderField = ({ input, type, placeholder, autoComplete }) => (
    <React.Fragment>
      <input
        {...input}
        styleName="register-form__input"
        placeholder={placeholder}
        type={type}
        name={input.name}
        autoComplete={autoComplete || 'on'}
        required="required"
        onInvalid={e => {
          if (e.target.name == 'email' && e.target.validity.typeMismatch) {
            e.target.setCustomValidity(i18n.t('sign_in.errors.invalid_email'))
          }

          if (e.target.validity.valueMissing) {
            e.target.setCustomValidity(i18n.t('sign_in.errors.required_field'))
          }
        }}
        onInput={e => {
          if (e.target.name != 'email') {
            e.target.setCustomValidity('')
          }
        }}
      />
    </React.Fragment>
  )

  renderTermsTxt = () => {
    const { i18n: { language }, t } = this.props
    const createLink = (label, doc) => {
      if (!doc) {
        return null
      }

      return (
        <a
          key={label}
          styleName="bl-link"
          href={`https://vacation-stay.jp/info/${doc}${language === 'en' ? '_en' : ''}.pdf`}
          target="_blank"
        >
          {t(`sign_in.register.${label}`)}
        </a>
      )
    }

    const getTermsTxt = (term) => {
      return Array.isArray(term) && term.map(label => {
        let doc = label
        switch (label) {
        case 'agreement':
          doc = documents.agreement
          break
        case 'host_service_agreement':
          doc = documents.host_service_agreement
          break
        case 'privacy_policy':
          doc = documents.privacy_policy
          break
        }
        return label.match(new RegExp("agreement|host_service_agreement|privacy_policy")) ? createLink(label, doc) : label
      })
    }

    return (
      <div
        styleName="register-form__term-and-policy"
        tx-content="translate_urls"
      >
        {getTermsTxt(t('sign_in.register.terms', { returnObjects: true }))}
      </div>
    )
  }

  render() {
    if (!this.props.modal.isRegisterModalShow) return null

    const { handleSubmit, t } = this.props

    return (
      <div id="register-form" styleName="register-form-background">
        <div
          styleName="register-form-container"
          onClick={() => {
            this.props.onCloseModal()
          }}
        >
          <div styleName="register-form-wrapper">
            <div
              styleName="register-form"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div
                styleName="register-form__close"
                onClick={() => {
                  this.props.onCloseModal()
                }}
              >
                <img src="/assets/image/common/icon_close.svg" />
              </div>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div styleName="register-form-vs-logo">
                  <img
                    alt="Vacation STAY"
                    src="/assets/image/common/logo_vacationstay_h_v2.0_rgb.svg"
                  />
                </div>
                <div styleName="register-form__error-message">
                  {this.props.login.signupError}
                </div>
                <div styleName="register-form__input-block">
                  <p styleName="register-form__input-rule">{this.props.t('sign_in.register.input_rule')}</p>
                  <Field
                    placeholder={t('sign_in.register.nickname')}
                    type="text"
                    name="nickname"
                    component={this.renderField}
                  />
                  <p styleName="register-form__input-note">
                    {t('sign_in.register.input_note')}
                  </p>
                </div>
                <div styleName="register-form__input-block">
                  <div styleName="register-form__name">
                    <Field
                      placeholder={t('sign_in.register.last_name')}
                      type="text"
                      name="last_name"
                      component={this.renderField}
                    />
                    <Field
                      placeholder={t('sign_in.register.first_name')}
                      type="text"
                      name="first_name"
                      component={this.renderField}
                    />
                  </div>
                </div>
                <div styleName="register-form__input-block">
                  <Field
                    placeholder={t('sign_in.mail_address')}
                    type="email"
                    name="email"
                    component={this.renderField}
                  />
                </div>
                <div styleName="register-form__input-block with-input-rule">
                  <p styleName="register-form__input-rule">
                    {t('sign_in.register.password_input_rule')}
                  </p>
                  <Field
                    autoComplete="new-password"
                    placeholder={t('sign_in.password')}
                    type="password"
                    name="password"
                    component={this.renderField}
                  />
                </div>
                {this.renderTermsTxt()}
                <p styleName="register-form__email-magazine-message">
                  {t('sign_in.register.email_magazine_message')}
                </p>
                <input
                  type="submit"
                  name="commit"
                  value={t('sign_in.register.label_registration')}
                  styleName="register-form__form-submit"
                  data-disable-with={t('sign_in.register.label_registration')}
                  onClick={e => {
                    // prevent html 5 default validation message
                    Array.from(document.querySelector('#register-form form')).forEach(input => {
                      input.setCustomValidity('')
                    })
                  }}
                />
                <div styleName="register-form__link-to-login">
                  <span>{t('sign_in.register.do_you_have_account')}</span>
                  <a
                    onClick={e => {
                      e.preventDefault()
                      this.props.modalActions.showLoginModal()
                    }}
                  >
                    {t('sign_in.login')}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(
    ({ login, modal }) => ({
      login,
      modal
    }),
    dispatch => ({
      loginActions: bindActionCreators(loginActions, dispatch),
      modalActions: bindActionCreators(modalActions, dispatch)
    })
  ),
  reduxForm({ form: 'register' })
)(withRouter(ModalRegister))
