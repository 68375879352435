import { polyfillObjectAssign } from "../../common/object_assign_polyfill"

/* global Pikaday */

class PikadayLoader {
  /**
   *
   * @param {{callback: Function}} options
   */
  constructor ({ callback } = {}) {
    this.callback = callback

    const checkinInput = document.querySelector('.js-date-range-input[name="checkin"]')
    const checkoutInput = document.querySelector('.js-date-range-input[name="checkout"]')
    const pikadayContainer = document.querySelector('.js-pikaday-container')

    this.checkinInput = checkinInput
    this.checkoutInput = checkoutInput
    this.pikadayContainer = pikadayContainer

    this.dateToString = this.dateToString.bind(this)
    this.parseDate = this.parseDate.bind(this)
  }

  loadPikadayIfNeeded () {
    polyfillObjectAssign()
    const isAndroid = navigator.userAgent.indexOf('Android') !== -1
    if(isAndroid) {
      if(this.callback) {
        this.callback()
      }
      return
    }
    const jsPath = document.querySelector('.js-pikaday-js-path-input').value
    const cssPath = document.querySelector('.js-pikaday-css-path-input').value
    const themePath = document.querySelector('.js-pikaday-theme-path-input').value

    const script = document.createElement('script')
    script.src = jsPath
    const cssLink = document.createElement('link')
    cssLink.rel = 'stylesheet'
    cssLink.href = cssPath
    const themeLink = document.createElement('link')
    themeLink.rel = 'stylesheet'
    themeLink.href = themePath

    let loadCount = 0
    const onLoad = (e) => {
      loadCount++
      if(loadCount == 3) {
        this.bindDateInputs()
      }
    }

    script.onload = onLoad
    cssLink.onload = onLoad
    themeLink.onload = onLoad
    document.head.appendChild(cssLink)
    document.head.appendChild(script)
    document.head.appendChild(themeLink)
  }

  bindDateInputs () {
    const { checkinInput, checkoutInput } = this
    const self = this
    const inputs = document.querySelectorAll('.js-date-range-input')
    let hasFocused = false
    for(let i = 0; i < inputs.length; i++) {
      const input = inputs[i]
      if(input.hasAttribute('data-has-focused')) {
        hasFocused = true
      }
      const container = input.closest('.js-date-range-input-container')
      container.setAttribute('data-is-pikaday', 'true')
    }
    if(!hasFocused) {
      checkinInput.type = 'text'
      checkoutInput.type = 'text'
      checkinInput.autocomplete = 'off'
      checkoutInput.autocomplete = 'off'
      const checkinPicker = new Pikaday(
        Object.assign(
          {},
          this.pikadayOptions,
          {
            field: checkinInput,
            minDate: new Date(),
            onSelect: function() {
              self.checkinDateChangeCallback()
            },
            onClose: function () {
              self.checkinDateChangeCallback(true)
              const shouldFocus = this.getDate() &&
                (document.activeElement == document.body || document.activeElement == checkinInput)
              if(shouldFocus) {
                if(self.isDesktopMode) {
                  self.focusInput(checkoutInput)
                } else {
                  checkoutPicker.show()
                }
              }
            }
          }
        )
      )
      const checkoutPicker = new Pikaday(
        Object.assign(
          {},
          this.pikadayOptions,
          {
            field: checkoutInput,
            minDate: new Date(),
            onSelect: function () {
              self.checkoutDateChangeCallback()
            },
            onClose () {
              self.checkoutDateChangeCallback(true)
            }
          }
        )
      )

      this.overrideFocus(checkinInput, checkinPicker, checkoutPicker)
      this.overrideFocus(checkoutInput, checkoutPicker, checkinPicker)
      if(checkinPicker.getDate()) {
        this.checkinDateChangeCallback(true)
      }
      if(checkoutPicker.getDate()) {
        this.checkoutDateChangeCallback(true)
      }
      this.checkinPicker = checkinPicker
      this.checkoutPicker = checkoutPicker
    }
    if(this.callback) {
      this.callback()
    }
  }

  focusInput(input) {
    Object.getPrototypeOf(input).focus.call(input)
  }

  overrideFocus (input, picker, otherPicker) {
    input.addEventListener('touchend', (e) => {
      if(!picker.isVisible()) {
        e.preventDefault()
        if(document.activeElement) {
          document.activeElement.blur()
        }
        picker.show()
        otherPicker.hide()
      }
    })
    input.focus = function () {}
  }

  checkinDateChangeCallback(closed = false) {
    const { checkinPicker, checkoutPicker } = this

    const checkinDate = checkinPicker.getDate()
    const checkoutDate = checkoutPicker.getDate()
    if(!checkinDate) {
      return
    }

    if(closed) {
      const checkinDate = checkinPicker.getDate()
      checkinPicker.setStartRange(checkinDate)
      checkinPicker.gotoDate(checkinDate)
      if(checkoutDate) {
        const targetDate = new Date(checkoutDate.getFullYear(), checkoutDate.getMonth() - 1, checkoutDate.getDate())
        checkoutPicker.gotoDate(targetDate)
        checkoutPicker.gotoDate(checkoutDate)
      } else {
        const targetDate = new Date(checkinDate.getFullYear(), checkinDate.getMonth() + 1, checkinDate.getDate())
        checkoutPicker.gotoDate(targetDate)
        checkoutPicker.gotoDate(checkinDate)
      }
    }
    checkoutPicker.setStartRange(checkinDate)
    checkoutPicker.setMinDate(new Date(checkinDate.getTime() + 86400000))
    if(checkoutDate && checkoutDate.getTime() <= checkinDate.getTime()) {
      checkoutPicker.setDate(null)
      checkoutPicker.setEndRange(null)
    }
  }

  checkoutDateChangeCallback(closed = false) {
    const { checkinPicker, checkoutPicker } = this
    const checkoutDate = checkoutPicker.getDate()
    checkinPicker.setEndRange(checkoutDate)
    if(closed) {
      checkoutPicker.setEndRange(checkoutDate)
    }
  }

  get pikadayOptions () {
    const now = new Date()
    return {
      maxDate: new Date(now.getFullYear() + 2, now.getMonth(), now.getDate()),
      toString: this.dateToString,
      parseDate: this.parseDate,
      i18n: this.i18n,
      yearSuffix: (this.isJapanese || this.isChinese) ? '年' : '',
      firstDay: 0,
      showMonthAfterYear: (this.isJapanese || this.isChinese),
      numberOfMonths: this.shouldShowTwoMonths ? 2 : 1,
      container: this.shouldShowTwoMonths ? this.pikadayContainer : null,
      theme: 'notranslate'
    }
  }

  get shouldShowTwoMonths () {
    return this.isDesktopMode
  }

  get isDesktopMode () {
    return window.innerWidth > this.minTwoMonthWidth
  }

  get minTwoMonthWidth () {
    return 980
  }

  get i18n () {
    switch (this.locale) {
    case 'en':
      return {
        previousMonth: '',
        nextMonth: '',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      }
    case 'zh-TW':
      return {
        previousMonth: '',
        nextMonth: '',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        weekdays: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
        weekdaysShort: ['日', '一', '二', '三', '四', '五', '六']
      }
    default:
      return {
        previousMonth: '',
        nextMonth: '',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
      }
    }
  }

  dateToString (date) {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    switch (this.locale) {
    case 'en':
      return `${month}/${day}/${year}`
    default:
      return `${year}/${month}/${day}`
    }
  }

  parseDate(dateString, format) {
    if(this.locale == 'en') {
      // TODO: en !== USA
      const parts = dateString.split('/')
      const month = parseInt(parts[0], 10) - 1
      const day = parseInt(parts[1], 10)
      const year = parseInt(parts[2], 10)
      return new Date(year, month, day)
    } else {
      const parts = dateString.split('/')
      const year = parseInt(parts[0], 10)
      const day = parseInt(parts[1], 10)
      const month = parseInt(parts[2], 10) - 1
      return new Date(year, month, day)
    }
  }

  clearInvalidDates () {
    if(!this.isBound) {
      return
    }
    const { checkinInput, checkoutInput } = this
    const checkinDate = this.parseDate(checkinInput.value)
    const checkoutDate = this.parseDate(checkoutInput.value)
    if(isNaN(checkinDate.getTime())) {
      checkinInput.value = ''
    }
    if(isNaN(checkoutDate.getTime())) {
      checkoutInput.value = ''
    }
  }

  get isBound () {
    const containers = document.querySelectorAll('.js-date-range-input-container')
    for(let i = 0; i < containers.length; i++) {
      const container = containers[i]
      if(container.getAttribute('data-is-pikaday') != 'true') {
        return false
      }
    }
    return true
  }

  get isJapanese () {
    return this.locale == 'ja'
  }

  get isChinese () {
    return this.locale == 'zh-TW'
  }

  get locale () {
    return (this.languageLocaleMeta && this.languageLocaleMeta.content) || 'ja'
  }

  get languageLocaleMeta () {
    return document.querySelector('meta[name="language-locale"]')
  }
}

export function clearInvalidDates () {
  const loader = new PikadayLoader()
  return loader.clearInvalidDates()
}

export function loadPikadayIfNeeded (callback) {
  const loader = new PikadayLoader({
    callback
  })
  loader.loadPikadayIfNeeded()
}
