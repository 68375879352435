import 'intersection-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from "react-redux"
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/root_reducer.js'
import { loadPikadayIfNeeded } from './pikaday_loader'
import { bindSearchForm } from './search_form_binder'
import { bindModal } from './modal_binder'
import { loadSwiper } from './swiper_loader'
import lozad from 'lozad'
import Modal from '../../common/components/modal_dialog/modal_wrapper_component'

const observer = lozad() // lazy loads elements with default selector as '.lozad'
observer.observe()

let store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)

bindModal()
bindSearchForm()
loadPikadayIfNeeded(function () {
  loadSwiper(function () {
    const ua = window.navigator.userAgent.toLowerCase()
    const isIE11 = ua.indexOf('trident/7') !== -1
    if (isIE11) {
      var evt = document.createEvent('UIEvents')
      evt.initUIEvent('resize', true, false, window, 0)
      window.dispatchEvent(evt)
    }
  })
})

document.addEventListener('DOMContentLoaded', () => {
  const root = document.querySelector('.modal-portal')
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <Route path='/:locale?/' render={(props) => <Modal {...props} />} />
      </Provider>
    </BrowserRouter>,
    root
  )

  document.querySelector('.show-more a').addEventListener('click', function(event) {
    event.preventDefault()
    document.querySelector('#rakuten-stay-pickup').classList.add('revealed')
  })
})
