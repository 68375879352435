import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import ModalLogin from './modal_login_component'
import ModalSocialLogin from './modal_social_login_component'
import ModalRegister from './modal_register_component'
import ModalCommon from './modal_common_component'
import * as modalActions from '../../actions/modal'
import * as loginActions from '../../actions/login'
import '../../../i18n'

const MODAL_TYPE = {
  LOGIN: 'login',
  SOCIAL_LOGIN: 'social-login',
  REGISTER: 'register'
}

class ModalWrapper extends Component {
  constructor(props) {
    super(props)

    this.bodyEl = document.getElementsByTagName('body')[0]
    this.modalPortal = document.querySelector('.modal-portal')
    this.modalOpenTriggers = document.querySelectorAll('.js-modal-open-trigger')
    this.menuWindow = document.querySelector('.js-sp-menu-window')

    this.onOpenModal = this.onOpenModal.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)

    this.onOpenModalByTarget = e => {
      e.preventDefault()
      this.onOpenModal(
        e.target.getAttribute('data-target'),
        e.target.getAttribute('data-url')
      )
    }
  }

  static propTypes = {
    login: PropTypes.shape({
      afterLoginUrl: PropTypes.string,
      email: PropTypes.string.isRequired,
      isLogin: PropTypes.bool.isRequired,
      isComplete: PropTypes.bool,
      signinError: PropTypes.string,
      signupError: PropTypes.string
    }).isRequired,
    modal: PropTypes.shape({
      scrollTop: PropTypes.number,
      display: PropTypes.bool,
      isCommonModalShow: PropTypes.bool.isRequired,
      isLoginModalShow: PropTypes.bool.isRequired,
      isRegisterModalShow: PropTypes.bool.isRequired,
      isSocialLoginModalShow: PropTypes.bool.isRequired
    }),
    modalActions: PropTypes.shape({
      hideAllModal: PropTypes.func.isRequired,
      hideModal: PropTypes.func.isRequired,
      showCommonModal: PropTypes.func.isRequired,
      showLoginModal: PropTypes.func.isRequired,
      showModal: PropTypes.func.isRequired,
      showRegisterModal: PropTypes.bool.isRequired,
      showSocialLoginModal: PropTypes.bool.isRequired
    })
  }

  componentDidMount() {
    this.modalPortal = document.querySelector('.modal-portal')
    const triggers = this.modalOpenTriggers
    Array.prototype.forEach.call(triggers, elm => {
      elm.addEventListener('click', this.onOpenModalByTarget)
    })
  }

  componentWillUnmount() {
    const triggers = this.modalOpenTriggers
    Array.prototype.forEach.call(triggers, elm => {
      elm.removeEventListener('click', this.onOpenModalByTarget)
    })
    this.modalPortal = null
  }

  fixBody() {
    this.props.modalActions.setState({ scrollTop: window.scrollY })
    this.bodyEl.classList.add('no-scrolling')
    document.body.style.top = `-${this.props.modal.scrollTop}px`
  }

  unfixBody() {
    if (!this.menuWindow.classList.contains('open')) {
      // スマホ時メニューを開いている場合は 'no-scrolling' はつけたままにする
      this.bodyEl.classList.remove('no-scrolling')
      window.scrollTo(0, this.props.modal.scrollTop)
    }
  }

  onOpenModal(target, returnUrl) {
    this.fixBody()
    this.props.loginActions.setState({ returnUrl })

    switch (target) {
    case MODAL_TYPE.LOGIN:
      this.props.modalActions.showLoginModal()
      break
    case MODAL_TYPE.SOCIAL_LOGIN:
      this.props.modalActions.showSocialLoginModal()
      break
    case MODAL_TYPE.REGISTER:
      this.props.modalActions.showRegisterModal()
      break
    default:
      return null
    }
  }

  onCloseModal() {
    this.unfixBody()
    this.props.loginActions.setState({ returnUrl: '' })
    this.props.modalActions.hideAllModal()
  }

  renderModal() {
    this.bodyEl.classList.add('no-scrolling')

    if (this.props.modal.isLoginModalShow) {
      return (
        <ModalLogin
          {...this.props}
          onCloseModal={this.onCloseModal}
        />
      )
    } else if (this.props.modal.isSocialLoginModalShow) {
      return (
        <ModalSocialLogin
          {...this.props}
          onCloseModal={this.onCloseModal}
        />
      )
    } else if (this.props.modal.isRegisterModalShow) {
      return (
        <ModalRegister
          {...this.props}
          onCloseModal={this.onCloseModal}
        />
      )
    } else if (this.props.modal.isCommonModalShow) {
      return (
        <ModalCommon
          {...this.props}
          onCloseModal={this.onCloseModal}
        />
      )
    } else {
      if (!this.menuWindow.classList.contains('open')) {
        // スマホ時メニューを開いている場合は 'no-scrolling' はつけたままにする
        this.bodyEl.classList.remove('no-scrolling')
      }
      return null
    }
  }

  render() {
    if (this.props.login.isLogin) return null
    return ReactDOM.createPortal(this.renderModal(), this.modalPortal)
  }
}

export default compose(
  connect(
    ({ login, modal }) => ({ login, modal }),
    dispatch => ({
      loginActions: bindActionCreators(loginActions, dispatch),
      modalActions: bindActionCreators(modalActions, dispatch)
    })
  ),
  withNamespaces()
)(ModalWrapper)
