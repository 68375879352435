import React from 'react'
import Cookies from 'js-cookie'

export class StringHelper{
  getPreferredLanguageString = (data, lang = null) => {
    if (data != undefined) {
      // Priorif "lang" specified in code, it's primary.
      if (lang == null) {
        lang = Cookies.get('language')
      }
      if (typeof lang == 'string') {
        const langCode = lang.replace('_', '-')
        if (data[langCode] != undefined || data[`auto_${langCode}`] != undefined) {
          return data[langCode] != undefined ? data[langCode] : data[`auto_${langCode}`]
        }
      }

      // Change default language by TLD (.jp or .com).
      lang = location.hostname.match(/^vacation-stay.com$/) != null ? 'en' : 'ja'
      if (data[lang] != undefined || data[`auto_${lang}`] != undefined) {
        return data[lang] != undefined ? data[lang] : data[`auto_${lang}`]
      }
    }
    return ''
  }

  nl2br = (src) => {
    if (typeof src != 'string') {
      return ''
    }
    const newlineRegex = /(\r\n|\r|\n)/g
    return src.split(newlineRegex).map(function(line, index) {
      if (line.match(newlineRegex)) {
        return <br key={index}/>
      }
      return <span key={index}>{line}</span>
    })
  }
}

const stringHelper = new StringHelper()
export default stringHelper
