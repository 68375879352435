import { loadScripts } from '../../common/async_script_loader'
import { polyfillObjectAssign } from "../../common/object_assign_polyfill"

const CONFIG = {
  rakutenStayPickup: {
    slidesPerView: 2,
    spaceBetween: 16,
    breakpoints: {
      559: { disabled: true }
    }
  },
  pickup: {
    slidesPerView: 4,
    spaceBetween: 16,
    breakpoints: {
      1079: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      559: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  }
}

export function loadSwiper (callback) {
  const swiperElement = document.querySelectorAll(".js-swiper")
  const nodes = Array.prototype.slice.call(swiperElement, 0)
  nodes.forEach(function(el) {
    const swiperSlide = el.querySelector(".js-swiper-slide")

    if(!swiperSlide) {
      callback()
      return
    }

    loadScripts(['.js-swiper-js-path-input'], function () {
      el.setAttribute('data-state', 'ready')
      setupSwiper(el)
      callback()
    })
  })
}

function getSiblings(n) {
  const siblings = Array.prototype.filter.call(n.parentNode.children, function(child) {
    return child !== n
  })
  return {
    prevEl: siblings[0],
    nextEl: siblings[1]
  }
}

function setupSwiper(el) {
  polyfillObjectAssign()
  const { prevEl, nextEl } = getSiblings(el)

  /* global Swiper */
  const options = {
    navigation: {
      nextEl,
      prevEl
    }
  }

  function initSwiper() {
    if (el) {
      const config = CONFIG[el.getAttribute('data-swiper-id')]
      const disabled = config.breakpoints && Object.keys(config.breakpoints).some(width => (
        window.matchMedia(`(max-width: ${width}px)`).matches && config.breakpoints[width].disabled
      ))

      if (disabled) {
        if (el.swiper) {
          el.swiper.destroy()
        }
      } else {
        if (!el.swiper) {
          new Swiper(el, Object.assign({}, options, config)) // eslint-disable-line
        }
        const h = el.querySelector('.js-swiper-slide-image').clientHeight
        const buttonTop = h / 2 + 16 + 'px'
        prevEl.style.top = buttonTop
        nextEl.style.top = buttonTop
      }

      el.setAttribute('data-state', 'initialized')
    }
    return null
  }

  initSwiper()
  window.addEventListener('resize', initSwiper)
}
