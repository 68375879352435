import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/login'
import stringHelper from '../../helpers/string_helper'
import './modal_common_component.scss'

class ModalCommon extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    loginActions: PropTypes.object.isRequired,
    onCloseModal: PropTypes.func.isRequired
  }

  onSubmit = (e) => {
    e.preventDefault()
    if (this.props.modal.content.onClick) {
      this.props.modal.content.onClick()
      this.props.onCloseModal()
    }
  }

  render() {
    if (!this.props.modal.isCommonModalShow) return null

    return (
      <div id="modal-common" styleName="modal-common-background">
        <div
          styleName="modal-common-container"
          onClick={() => {
            this.props.onCloseModal()
          }}
        >
          <div styleName="modal-common-wrapper">
            <div
              styleName="modal-common-form"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div
                styleName="modal-common__close"
                onClick={() => {
                  this.props.onCloseModal()
                }}
              >
                <img src="/assets/image/common/icon_close.svg" />
              </div>
              <div styleName="modal-common-message">
                <form onSubmit={(e) => { e.preventDefault() }}>
                  {this.props.modal.content.title &&
                    <h2 styleName="modal-common-message__heading">
                      {stringHelper.nl2br(this.props.modal.content.title)}
                    </h2>
                  }
                  {this.props.modal.content.subTitle &&
                    <p styleName="modal-common-message__subheading">
                      {stringHelper.nl2br(this.props.modal.content.subTitle)}
                    </p>
                  }
                  {this.props.modal.content.body &&
                    <p styleName="modal-common-message__text">
                      {stringHelper.nl2br(this.props.modal.content.body)}
                    </p>
                  }
                  {this.props.modal.content.button &&
                    <div styleName="modal-common-message__button">
                      <button type="click" onClick={this.onSubmit}>
                        {this.props.modal.content.button}
                      </button>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ language, modal }) => ({ language, modal }),
  dispatch => ({
    loginActions: bindActionCreators(loginActions, dispatch)
  })
)(ModalCommon)
