import PostApi from '../../common/api/post'
import GetApi from '../../common/api/get'

import {
  SET_STATE_LOGIN,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  IS_LOGIN,
  IS_LOGIN_SUCCESS,
  IS_LOGIN_FAILURE,
  CLEAR_AUTH_ERRORS,
  RESEND_CONFIRMATION_EMAIL_SUCCESS,
  RESEND_CONFIRMATION_EMAIL_FAILURE
} from '../actionTypes/login'

export const setState = params => ({ type: SET_STATE_LOGIN, params })

export const signup = (formProps, returnUrl) => async dispatch => {
  dispatch({ type: SIGNUP })
  try {
    const postApi = new PostApi({ skipReload: true })
    await postApi.execute(routeUrlHelper.usersPath({ returnUrl }), formProps)
    return dispatch({ type: SIGNUP_SUCCESS, email: formProps.email })
  } catch (e) {
    if (e.statusCode == 422) { location.reload() }
    return Promise.reject(dispatch({ type: SIGNUP_FAILURE, payload: e.body.error }))
  }
}

export const signin = (formProps, returnUrl) => async dispatch => {
  dispatch({ type: SIGNIN })
  try {
    const postApi = new PostApi({ skipReload: true })
    const response = await postApi.execute(
      routeUrlHelper.loginUsersPath({ returnUrl }),
      formProps
    )
    const res = JSON.parse(response.text)
    return dispatch({ type: SIGNIN_SUCCESS, locale: res['locale'] })
  } catch (e) {
    if (e.statusCode == 422) { location.reload() }
    return Promise.reject(dispatch({
      type: SIGNIN_FAILURE,
      statusCode: e.statusCode,
      payload: e.body.error
    }))
  }
}

export const isLogin = () => async dispatch => {
  dispatch({ type: IS_LOGIN })
  try {
    const getApi = new GetApi()
    const res = await getApi.execute(routeUrlHelper.isLoginUsersPath(), {locale: languageHelper.locale})
    return dispatch({ type: IS_LOGIN_SUCCESS, payload: res.body })
  } catch (e) {
    return Promise.reject(dispatch({ type: IS_LOGIN_FAILURE, payload: e.body.error }))
  }
}

export const clearErrors = () => ({ type: CLEAR_AUTH_ERRORS })

export const resendConfirmationEmail = (email) => async dispatch => {
  try {
    const postApi = new PostApi()
    await postApi.execute(routeUrlHelper.resendConfirmationEmailUsersPath(), { email }) // prettier-ignore
    return dispatch({ type: RESEND_CONFIRMATION_EMAIL_SUCCESS })
  } catch (e) {
    return Promise.reject(dispatch({ type: RESEND_CONFIRMATION_EMAIL_FAILURE }))
  }
}
