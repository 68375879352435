import {
  SET_STATE_LOGIN,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  IS_LOGIN_SUCCESS,
  CLEAR_AUTH_ERRORS,
  RESEND_CONFIRMATION_EMAIL_SUCCESS,
  RESEND_CONFIRMATION_EMAIL_FAILURE
} from '../actionTypes/login'

export const initialState = {
  email: '',
  returnUrl: '',
  afterLoginUrl: '',
  isLogin: false,
  isComplete: false,
  statusCode: null,
  signinError: '',
  signupError: ''
}

export default function main(state = initialState, action) {
  switch (action.type) {
  case SET_STATE_LOGIN:
    return {
      ...state,
      ...action.params
    }
  case SIGNUP_SUCCESS:
    return {
      ...state,
      email: action.email
    }
  case SIGNUP_FAILURE:
    return {
      ...state,
      signupError: action.payload
    }
  case SIGNIN_SUCCESS:
    return {
      ...state,
      isLogin: true
    }
  case SIGNIN_FAILURE:
    return {
      ...state,
      statusCode: action.statusCode,
      signinError: action.payload
    }
  case IS_LOGIN_SUCCESS:
    return {
      ...state,
      isLogin: action.payload
    }
  case CLEAR_AUTH_ERRORS:
    return {
      ...state,
      statusCode: null,
      signinError: '',
      signupError: '',
      message: ''
    }
  case RESEND_CONFIRMATION_EMAIL_SUCCESS:
  case RESEND_CONFIRMATION_EMAIL_FAILURE:
  default:
    return state
  }
}
