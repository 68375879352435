export const SET_STATE_LOGIN = 'SET_STATE_LOGIN'
export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const SIGNIN = 'SIGNIN'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE'
export const IS_LOGIN = 'IS_LOGIN'
export const IS_LOGIN_SUCCESS = 'IS_LOGIN_SUCCESS'
export const IS_LOGIN_FAILURE = 'IS_LOGIN_FAILURE'
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS'
export const RESEND_CONFIRMATION_EMAIL_SUCCESS = 'RESEND_CONFIRMATION_EMAIL_SUCCESS'
export const RESEND_CONFIRMATION_EMAIL_FAILURE = 'RESEND_CONFIRMATION_EMAIL_FAILURE'
