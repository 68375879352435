import request from 'superagent'

export default class PostApi{
  constructor({ skipReload = false } = {}) {
    this.skipReload = skipReload
  }

  getCSRFtoken(){
    let token
    if (document.querySelector('meta[name="csrf-token"]') ){
      token = document.querySelector('meta[name="csrf-token"]').content
    }
    return token
  }

  execute(url, params, successCallback = () => {}, errCallback = () => {}){
    return new Promise((resolve, reject) => {
      request.post(url)
        .set('X-CSRF-Token', this.getCSRFtoken())
        .set('Accept', 'application/json')
        .send(params)
        .end((err, res) => {
          if (res && (res.statusCode == 401 || res.statusCode == 422)) {
            if(!this.skipReload) {
              location.reload()
            }
          }
          if (err) {
            errCallback(res)
            reject(res)
          }else{
            successCallback(res)
            resolve(res)
          }
        })
    })
  }
}
