import {
  SET_STATE_MODAL,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_LOGIN_MODAL,
  SHOW_SOCIAL_LOGIN_MODAL,
  SHOW_REGISTER_MODAL,
  SHOW_COMMON_MODAL,
  HIDE_ALL_MODAL
} from '../actionTypes/modal'

export const setState = (params) => ({ type: SET_STATE_MODAL, params })
export const showModal = () => ({ type: SHOW_MODAL })
export const hideModal = () => ({ type: HIDE_MODAL })
export const hideAllModal = () => ({ type: HIDE_ALL_MODAL })

export const showCommonModal = ({
  title, subTitle, body, button, onClick
}) => dispatch => {
  dispatch({ type: HIDE_ALL_MODAL })
  dispatch({
    type: SHOW_COMMON_MODAL,
    payload: { title, subTitle, body, button, onClick }
  })
}

export const showLoginModal = () => dispatch => {
  dispatch({ type: HIDE_ALL_MODAL })
  dispatch({ type: SHOW_LOGIN_MODAL })
}

export const showSocialLoginModal = () => dispatch => {
  dispatch({ type: HIDE_ALL_MODAL })
  dispatch({ type: SHOW_SOCIAL_LOGIN_MODAL })
}

export const showRegisterModal = () => dispatch => {
  dispatch({ type: HIDE_ALL_MODAL })
  dispatch({ type: SHOW_REGISTER_MODAL })
}
