class AsyncScriptLoader {
  /**
   *
   * @param {{inputClassNames: Array<string>, callback: Function}} options
   */
  constructor ({ inputClassNames = [], callback }) {
    this.inputClassNames = inputClassNames
    this.callback = callback

    this.finishedCount = 0

    this.scriptLoadCallback = this.scriptLoadCallback.bind(this)
  }

  load () {
    var self = this
    this.inputClassNames.length && this.inputClassNames.forEach(function(cls) {
      const input = document.querySelector(cls)
      const url = input.value
      const script = document.createElement('script')
      script.src = url
      script.addEventListener('load', self.scriptLoadCallback)
      document.head.appendChild(script)
    })
  }

  scriptLoadCallback () {
    this.finishedCount += 1
    if(this.finishedCount == this.inputClassNames.length ) {
      this.callback()
    }
  }
}

/**
 *
 * @param {Array<string>} inputClassNames
 * @param {Function} callback
 */
export function loadScripts(inputClassNames, callback) {
  const loader = new AsyncScriptLoader({
    inputClassNames,
    callback
  })
  loader.load()
}
