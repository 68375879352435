const initialState = {
  lang: ''
}

const language = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_LANGUAGE':
    return {
      lang: action.lang
    }
  default:
    return state
  }
}

export default language
