import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import login from '../../../common/reducers/login'
import language from '../../../common/reducers/language'
import modal from '../../../common/reducers/modal'

const rootReducer = combineReducers({
  login,
  language,
  modal,
  form: formReducer
})
export default rootReducer
