export function getDistanceFromLatLonInKm (lat1, lon1, lat2, lon2) {
  const radius = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = radius * c // Distance in km
  return d
}

export function getSearchRadiusForGoogleMapsViewport (viewport) {
  const northEast = viewport.getNorthEast()
  const southWest = viewport.getSouthWest()
  const radius = 1000 * Math.max(10, getDistanceFromLatLonInKm(northEast.lat(), northEast.lng(), southWest.lat(), southWest.lng()) / 2)
  return radius
}

function deg2rad(deg) {
  return deg * Math.PI / 180
}
