export function bindModal () {
  const areaModal = document.querySelector('.js-area-modal')
  const areaModalBg = document.querySelector('.js-area-modal-bg')
  const openButtons = document.querySelectorAll('.js-area-modal-open-button')
  const closeButtons = document.querySelectorAll('.js-area-modal-close-button')

  for (let i = 0; i < openButtons.length; i++) {
    const openButton = openButtons[i]
    openButton && openButton.addEventListener('click', openModal)
  }

  for (let i = 0; i < closeButtons.length; i++) {
    const closeButton = closeButtons[i]
    closeButton && closeButton.addEventListener('click', closeModal)
  }

  areaModalBg && areaModalBg.addEventListener('click', closeModal)

  function openModal(e) {
    document.body.classList.add('no-scrolling')
    areaModal.style.display = 'block'
    document.addEventListener('keydown', handleKeyDown)
  }

  function closeModal () {
    document.body.classList.remove('no-scrolling')
    areaModal.style.display = 'none'
    document.removeEventListener('keydown', handleKeyDown)
  }

  const focusableElements = (areaModal && areaModal.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')) || []
  const firstFocusableElement = focusableElements[0]
  const lastFocusableElement = focusableElements[focusableElements.length - 1]

  function handleKeyDown (e) {
    const KEY_TAB = 9
    const KEY_ESC = 27

    function handleBackwardTab() {
      if ( document.activeElement === firstFocusableElement ) {
        e.preventDefault()
        lastFocusableElement.focus()
      }
    }
    function handleForwardTab() {
      if ( document.activeElement === lastFocusableElement ) {
        e.preventDefault()
        firstFocusableElement.focus()
      }
    }

    switch(e.keyCode) {
    case KEY_TAB:
      if ( focusableElements.length === 1 ) {
        e.preventDefault()
        break
      }

      if ( e.shiftKey ) {
        handleBackwardTab()
      } else {
        handleForwardTab()
      }

      break
    case KEY_ESC:
      closeModal()
      break
    default:
      break
    }
  }
}
