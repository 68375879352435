import { getSearchRadiusForGoogleMapsViewport } from '../../common/helpers/location_helper'
import { clearInvalidDates } from './pikaday_loader'
import { AVAILABLE_COUNTRY_CODES } from '../../common/constants/classifications'

class SearchFormBinder {
  constructor () {
    const form = document.querySelector('.js-search-form')
    this.form = form

    const destinationInput = form.querySelector('.js-hidden-destination-input')
    const radiusInput = form.querySelector('input[name="radius"]')
    const latInput = form.querySelector('input[name="lat"]')
    const lonInput = form.querySelector('input[name="lon"]')
    const placeInput = document.querySelector('.js-place-search-input')
    const checkinInput = document.querySelector('.js-date-range-input[name="checkin"]')
    const checkoutInput = document.querySelector('.js-date-range-input[name="checkout"]')
    const adultsSelect = form.querySelector('select[name="adults"]')

    this.destinationInput = destinationInput
    this.radiusInput = radiusInput
    this.latInput = latInput
    this.lonInput = lonInput
    this.placeInput = placeInput
    this.checkinInput = checkinInput
    this.checkoutInput = checkoutInput
    this.adultsSelect = adultsSelect

    this.checkinInputChangeCallback = this.checkinInputChangeCallback.bind(this)
    this.adultsSetCallback = this.adultsSetCallback.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  bindSearchForm(country) {
    const {form, checkinInput, adultsSelect} = this
    checkinInput.addEventListener('change', this.checkinInputChangeCallback)
    if(checkinInput.value) {
      this.checkinInputChangeCallback()
    }

    if (typeof google == 'undefined') {
      document.addEventListener('DOMContentLoaded', () => this.bindPlaceInput(country))
    } else {
      this.bindPlaceInput(country)
    }

    adultsSelect.addEventListener('change', this.adultsSetCallback)
    if(adultsSelect.value) {
      this.adultsSetCallback()
    }

    form.setAttribute('data-bound', 'true')
    form.addEventListener('submit', this.onSubmit)
  }

  bindPlaceInput(country) {
    /* global google */
    if (typeof google == 'undefined') {
      return
    }
    const { destinationInput, placeInput, radiusInput, latInput, lonInput } = this

    // デザインを優先させてrequiredを使わないことになりました
    placeInput.removeAttribute('required')
    placeInput.name = 'destinationSearch'
    destinationInput.name = 'destination'
    const autoComplete = new google.maps.places.Autocomplete(placeInput, {
      componentRestrictions: { country: country || AVAILABLE_COUNTRY_CODES }
    })

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace()
      if(place.geometry) {
        const location = place.geometry.location
        const viewport = place.geometry.viewport
        const radius = getSearchRadiusForGoogleMapsViewport(viewport)
        const lat = location.lat()
        const lon = location.lng()

        radiusInput.value = radius
        latInput.value = lat
        lonInput.value = lon
        destinationInput.value = place.name
      }
    })

    placeInput.addEventListener('change', () => {
      destinationInput.value = placeInput.value
      radiusInput.value = null
      latInput.value = null
      lonInput.value = null
    })
  }

  onSubmit (e) {
    clearInvalidDates()
  }

  resetDateInput (input) {
    const container = input.closest('.js-date-range-input-container')
    const cover = container.querySelector('.js-placeholder-cover')
    cover.style.display = null
    input.value = null
  }

  stringifyDate(date) {
    return [
      date.getFullYear(),
      ('0' + String(date.getMonth() + 1)).substr(-2),
      ('0' + String(date.getDate())).substr(-2)
    ].join('-')
  }

  adultsSetCallback() {
    const { adultsSelect } = this
    if(adultsSelect.value) {
      adultsSelect.setAttribute('data-has-changed', 'true')
    } else {
      adultsSelect.removeAttribute('data-has-changed', 'true')
    }
  }

  checkinInputChangeCallback() {
    const { checkinInput, checkoutInput } = this
    if(checkinInput.type == 'date') {
      const date = new Date(checkinInput.value)
      const minDate = new Date(date.getTime() + 86400000)
      const min = this.stringifyDate(minDate)
      checkoutInput.min = min
      if(checkoutInput.value) {
        const checkoutDate = new Date(checkoutInput.value)
        if(checkoutDate.getTime() <= date.getTime()) {
          this.resetDateInput(checkoutInput)
        }
      }
    }
  }

  static get instance () {
    return this._instance ? this._instance : (this._instance = new SearchFormBinder())
  }

  get locale () {
    return document.documentElement.lang || 'ja'
  }
}

export function bindSearchForm(country) {
  return SearchFormBinder.instance.bindSearchForm(country)
}
