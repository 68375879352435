import i18next from 'i18next'
import backend from "i18next-xhr-backend"
import { reactI18nextModule } from 'react-i18next'
import signInEn from '../../../config/locales/sign_in/en.json'
import signInJa from '../../../config/locales/sign_in/ja.json'
import signInZhTw from '../../../config/locales/sign_in/zh-TW.json'
import countryEn from '../../../config/locales/countries/en.json'
import countryJa from '../../../config/locales/countries/ja.json'
import countryZhTw from '../../../config/locales/countries/zh-TW.json'
import vietnamEn from '../../../config/locales/vietnam/en.json'
import vietnamJa from '../../../config/locales/vietnam/ja.json'
import vietnamZhTw from '../../../config/locales/vietnam/zh-TW.json'
import propertyEn from '../../../config/locales/property/en.json'
import propertyJa from '../../../config/locales/property/ja.json'
import propertyZhTw from '../../../config/locales/property/zh-TW.json'
import reservationEn from '../../../config/locales/reservation/en.json'
import reservationJa from '../../../config/locales/reservation/ja.json'
import reservationZhTw from '../../../config/locales/reservation/zh-TW.json'
import bankAccountEn from '../../../config/locales/bank_accounts/en.json'
import bankAccountJa from '../../../config/locales/bank_accounts/ja.json'
import bankAccountZhTw from '../../../config/locales/bank_accounts/zh-TW.json'
import usersEn from '../../../config/locales/users/en.json'
import usersJa from '../../../config/locales/users/ja.json'
import usersZhTw from '../../../config/locales/users/zh-TW.json'
import overseasJa from '../../../config/locales/overseas/ja.json'
import overseasEn from '../../../config/locales/overseas/en.json'
import overseasZhTw from '../../../config/locales/overseas/zh-TW.json'
import rakutenStayEn from '../../../config/locales/rakuten_stay/en.json'
import rakutenStayJa from '../../../config/locales/rakuten_stay/ja.json'

function locale() {
  const meta = document.querySelector('meta[name="language-locale"]')
  if (meta) {
    return meta.content
  }
  return 'ja'
}

i18next
  .use(backend)
  .use(reactI18nextModule)
  .init({
    lng: 'ja',
    fallbackLng: 'en',
    react: {
      wait: true
    },
    interpolation: {
      format: function(value, format, lang) {
        return (format === 'capitalize' && lang === 'en') ? value.charAt(0).toUpperCase() + value.slice(1) : value
      }
    },
    ns: ['sub_header', 'countries', 'sign_in', 'vietnam', 'property', 'reservation', 'bank_account', 'room_groups', 'search', 'sales', 'payments', 'new_listing', 'users', 'travels'],
    defaultNS: ['sign_in'],
    resources: {
      en: {
        sign_in: signInEn,
        sub_header: require('../../../config/locales/sub_header/en.json'),
        countries: countryEn,
        vietnam: vietnamEn,
        property: propertyEn,
        reservation: reservationEn,
        bank_account: bankAccountEn,
        room_groups: require('../../../config/locales/room_groups/en.json'),
        room: require('../../../config/locales/room/en.json'),
        new_listing: require('../../../config/locales/new_listing/en.json'),
        validation: require('../../../config/locales/validation/en.json'),
        classification: require('../../../config/locales/classification/en.json'),
        common: require('../../../config/locales/common/en.json'),
        search: require('../../../config/locales/search/en.json'),
        payments: require('../../../config/locales/payments/en.json'),
        sales: require('../../../config/locales/sales/en.json'),
        travels: require('../../../config/locales/travels/en.json'),
        users: usersEn,
        components: require('../../../config/locales/components/en.json'),
        overseas: overseasEn,
        listings: require('../../../config/locales/listings/en.json'),
        rakuten_stay: rakutenStayEn
      },
      ja: {
        sign_in: signInJa,
        sub_header: require('../../../config/locales/sub_header/ja.json'),
        countries: countryJa,
        vietnam: vietnamJa,
        property: propertyJa,
        reservation: reservationJa,
        bank_account: bankAccountJa,
        room_groups: require('../../../config/locales/room_groups/ja.json'),
        room: require('../../../config/locales/room/ja.json'),
        new_listing: require('../../../config/locales/new_listing/ja.json'),
        validation: require('../../../config/locales/validation/ja.json'),
        classification: require('../../../config/locales/classification/ja.json'),
        common: require('../../../config/locales/common/ja.json'),
        search: require('../../../config/locales/search/ja.json'),
        payments: require('../../../config/locales/payments/ja.json'),
        sales: require('../../../config/locales/sales/ja.json'),
        travels: require('../../../config/locales/travels/ja.json'),
        users: usersJa,
        components: require('../../../config/locales/components/ja.json'),
        overseas: overseasJa,
        listings: require('../../../config/locales/listings/ja.json'),
        rakuten_stay: rakutenStayJa
      },
      'zh-TW': {
        sign_in: signInZhTw,
        sub_header: require('../../../config/locales/sub_header/zh-TW.json'),
        countries: countryZhTw,
        vietnam: vietnamZhTw,
        property: propertyZhTw,
        reservation: reservationZhTw,
        bank_account: bankAccountZhTw,
        room_groups: require('../../../config/locales/room_groups/zh-TW.json'),
        room: require('../../../config/locales/room/zh-TW.json'),
        new_listing: require('../../../config/locales/new_listing/zh-TW.json'),
        validation: require('../../../config/locales/validation/zh-TW.json'),
        classification: require('../../../config/locales/classification/zh-TW.json'),
        common: require('../../../config/locales/common/zh-TW.json'),
        search: require('../../../config/locales/search/zh-TW.json'),
        payments: require('../../../config/locales/payments/zh-TW.json'),
        sales: require('../../../config/locales/sales/zh-TW.json'),
        travels: require('../../../config/locales/travels/zh-TW.json'),
        users: usersZhTw,
        components: require('../../../config/locales/components/zh-TW.json'),
        overseas: overseasZhTw,
        listings: require('../../../config/locales/listings/zh-TW.json')
      }
    }
  })

i18next.changeLanguage(locale())

export default i18next
