import i18n from '../../i18n'

export const autoExtendMonthSpanOptions = [
  {
    "value": 1,
    "name": i18n.t('classification:auto_extend_months.1')
  },
  {
    "value": 3,
    "name": i18n.t('classification:auto_extend_months.3')
  },
  {
    "value": 6,
    "name": i18n.t('classification:auto_extend_months.6')
  },
  {
    "value": 12,
    "name": i18n.t('classification:auto_extend_months.12')
  }
]

export const intervalDaysOptions = [
  {
    "value": 0,
    "name": i18n.t('classification:interval_days.no')
  },
  {
    "value": 1,
    "name": i18n.t('classification:interval_days.1')
  },
  {
    "value": 2,
    "name": i18n.t('classification:interval_days.2')
  },
  {
    "value": 3,
    "name": i18n.t('classification:interval_days.3')
  }
]

export const ROOM_TYPE = {
  ENTIRE_HOME: "entire_home",
  PRIVATE_ROOM: "private_room"
}

export const ASSIGNMENT_STATUS = {
  STAY: 'stay',
  INTERVAL: 'interval'
}

export const RATING = {
  1: '最悪',
  2: '悪い',
  3: 'まあまあ',
  4: 'とても良い',
  5: 'すばらしい'
}

export const ratingClassification = (value) => {
  if (value >= 4.5) {
    return 'Excellent!'
  } else if (value >= 4.0) {
    return 'Very Good'
  } else if (value >= 3.0) {
    return 'Good'
  }

  return ''
}

// ソートのタイプ
export const SORT_TYPE = {
  DISTANCE_ASC: "distance_asc",
  SIZE_DESC: "size_desc",
  PRICE_ASC: "price_asc",
  PRICE_DESC: "price_desc"
}

// 宿泊料金上限・下限の選択肢
export const PRICES = [
  '5000',
  '6000',
  '7000',
  '8000',
  '9000',
  '10000',
  '12000',
  '14000',
  '16000',
  '18000',
  '20000',
  '30000',
  '40000',
  '50000',
  '100000',
  '150000',
  '200000'
]

// 建物タイプ
export const PROPERTY_TYPE = {
  HOUSE: 'house',
  APARTMENT: 'apartment',
  HOTEL: 'hotel',
  COTTAGE: 'cottage',
  BUNGALOW: 'bungalow',
  GLAMPING: 'glamping'
}

// 検索可能な国コード
export const AVAILABLE_COUNTRY_CODES = ['jp', 'vn']
