import {
  SET_STATE_MODAL,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_LOGIN_MODAL,
  SHOW_SOCIAL_LOGIN_MODAL,
  SHOW_REGISTER_MODAL,
  SHOW_COMMON_MODAL,
  HIDE_ALL_MODAL
} from '../actionTypes/modal'

let initialState = {
  scrollTop: 0,
  display: false,
  content: {},
  isCommonModalShow: false,
  isLoginModalShow: false,
  isSocialLoginModalShow: false,
  isRegisterModalShow: false
}

const modal = (state = initialState, action) => {
  switch (action.type) {
  case SET_STATE_MODAL:
    return { ...state, ...action.params }
  case SHOW_MODAL:
    return { ...state, display: true }
  case HIDE_MODAL:
    return { ...state, display: false }
  case SHOW_COMMON_MODAL:
    return { ...state, isCommonModalShow: true, content: action.payload }
  case SHOW_LOGIN_MODAL:
    return { ...state, isLoginModalShow: true }
  case SHOW_SOCIAL_LOGIN_MODAL:
    return { ...state, isSocialLoginModalShow: true }
  case SHOW_REGISTER_MODAL:
    return { ...state, isRegisterModalShow: true }
  case HIDE_ALL_MODAL:
    return {
      ...state,
      content: {},
      isCommonModalShow: false,
      isLoginModalShow: false,
      isSocialLoginModalShow: false,
      isRegisterModalShow: false
    }
  default:
    return state
  }
}

export default modal
